import React, { useState, useRef } from 'react';
import { DatePicker, Select, Tooltip } from 'antd';
import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import HistoryModal from './HistoryModal';

const AppointmentSelector = ({
    open,
    inputRef,
    onBlur,
    onChange,
    currentDateValue,
    currentStatusValue,
    currentPickOrDelNumber,
    onKeyDown,
    onOk,
    statusOptions,
    rowSelectApptsColorClass,
    setData,
    resource,
    cellDataKey,
    delOrPickNumberColor,
    lastUpdatedData,
    lastUpdateAuthor,
    rowState,
    subIsDisabled,
    showRemoveIcon,
}) => {
    const selectRef = useRef(null);
    const extraInputRef = useRef(null);
    const extraTextRef = useRef(null);
    const [statusValue, setSelValue] = useState(currentStatusValue);
    const [dateVal, setDateVal] = useState(currentDateValue);
    const [pickOrDelNumber, setPickOrDelNumber] = useState(currentPickOrDelNumber);
    const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);

    const lastHistoryDateTextTooltip = `PREV: ${lastUpdatedData ? moment.utc(lastUpdatedData).utcOffset(4).format("MM/DD/YY") : ''} - ${lastUpdateAuthor ? lastUpdateAuthor : ''} `;
    const Abbr = lastUpdateAuthor?.split(' ')?.map(name => name.slice(0, 1)).join("").toUpperCase();
    const lastHistoryDateText = `PREV: ${lastUpdatedData ? moment.utc(lastUpdatedData).utcOffset(4).format("MM/DD/YY") : ''} - ${lastUpdateAuthor ? Abbr : '' } `;

    const handleInputChangeOnBlur = () => {
        if (resource === 'load') {
            if (cellDataKey === 'inboundPickAppointment') {
                setData(pickOrDelNumber, "inPickNummber", "textInput");
            } else {
                setData(pickOrDelNumber, "inDeliverNummber", "textInput");
            }
        } else {
            if (cellDataKey === 'outboundPickAppointment') {
                setData(pickOrDelNumber, "outPickNummber", "textInput");
            } else {
                setData(pickOrDelNumber, "outDeliverNummber", "textInput");
            }
        };
        inputRef.current.focus();
    };

    return (<>
        <DatePicker
            open={open}
            ref={inputRef}
            onBlur={dateVal => {
                if (dateVal.relatedTarget?.id === selectRef?.current?.props?.id) {
                    inputRef.current.focus();
                    return;
                }
                if (dateVal.relatedTarget?.id === extraInputRef?.current?.id) {
                    return;
                }
                if (dateVal.relatedTarget?.id === extraTextRef?.current?.id) {
                    return;
                }
                onBlur && onBlur(dateVal, statusValue);
            }}
            onChange={dateVal => {
                setDateVal(dateVal);
                onChange && onChange(dateVal, statusValue);
                if (dateVal === null) {
                    setData("");
                    setSelValue(2);
                    if (resource === 'load') {
                        setData(2, "inboundAppointmentStatus", "select");
                        setData(2, "dropInboundAppointmentStatus", "select");
                    } else {
                        setData(2, "outboundAppointmentStatus", "select");
                        setData(2, "pickOutboundAppointmentStatus", "select");
                    }
                }
            }}
            suffixIcon={null}
            allowClear={showRemoveIcon}
            showTime
            minuteStep={15}
            className={`spreadsheet-picker ${subIsDisabled && 'sub__disabled'}`}
            value={dateVal}
            format={Defines.Format.PrintDateTime}
            onKeyDown={e => onKeyDown && onKeyDown(e)}
            onOk={dateVal => onOk && onOk(dateVal, statusValue, pickOrDelNumber)}
            showToday={false}
            showNow={false}
            disabled={subIsDisabled}
            renderExtraFooter={() => (
                <>
                    <div className='appts-selector__status' onMouseDown={(e) => e.stopPropagation()}>
                        <div style={{ marginRight: '8px' }}>
                            <Select 
                                ref={selectRef} 
                                id="statusSelect"
                                className={rowSelectApptsColorClass(statusValue)}
                                value={statusValue}
                                onChange={
                                    value => {
                                        setSelValue(value);
                                    }}
                            >
                                {statusOptions.map((item, index) => (
                                    <Select.Option
                                        key={index}
                                        value={item.id}
                                        className={rowSelectApptsColorClass(item.id)}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <input  onBlur={handleInputChangeOnBlur}
                                    value={pickOrDelNumber}
                                    onChange={e => { setPickOrDelNumber(e.target.value) }}
                                    ref={extraInputRef} 
                                    type="text"
                                    placeholder={cellDataKey === 'inboundPickAppointment' || 
                                                cellDataKey === 'outboundPickAppointment' ? 'Pick #' : 'Del #'}
                                    id="externalInput"
                                    className='appts-selector__input'
                                    style={{ backgroundColor: delOrPickNumberColor ? delOrPickNumberColor : 'white' }}>
                            </input>
                        </div>
                        <Tooltip title={lastHistoryDateTextTooltip}>
                            <button 
                                className='appts-selector__history'
                                id="extraText"
                                onClick={() => setHistoryModalOpen(true)}
                                ref={extraTextRef}
                            >
                                {lastHistoryDateText}
                            </button>
                        </Tooltip>
                    </div>
                    {resource === 'outboundload' ? <div className='appt-calendar__suggestions-container'>
                        {rowState?.orderData?.dropApptOne || rowState?.orderData?.dropApptTwo ? 
                            <div className='appt-calendar__suggestions'>
                                APPT Suggestion Time: &nbsp; &nbsp;
                                {rowState?.orderData?.dropApptOne} 
                                &nbsp; &nbsp; {rowState?.orderData?.dropApptTwo ? '  |  ' : ''} &nbsp; &nbsp;
                                {rowState?.orderData?.dropApptTwo}
                            </div> : ''
                        }
                    </div> : null}
                </>
            )}
        />
        {isHistoryModalOpen &&
            <HistoryModal
                isHistoryModalOpen={isHistoryModalOpen}
                setHistoryModalOpen={setHistoryModalOpen}
                resource={resource}
                rowState={rowState}
                cellDataKey={cellDataKey}
                inputRef={inputRef}
                field='appt'>
            </HistoryModal>}
        </>
    );
};

export default AppointmentSelector;