import {
    ElementTypes,
    ElementValidations,
    Action,
    ListDefaults,
    ColumnType,
    FilterType,
    GenerateToolBars,
    DefaultPagination,
    ComponentTypes
} from '../../config';

import ContactTypeConfig from '../ContactType/config';

const Location = {
    Title: 'Location',
    Resource: 'Location',
    Master: 'formattedAddress',
    Key: 'id',
    IsRest: true,
    List: {
        DisableDownload: true,
        ImportGSheet: true,
        ExportGSheet: true,
        Defaults: ListDefaults,
        ShowRefreshButton: true,
        Search: '_all',
        Filter: {
            formattedAddress: {
                Type: FilterType.Text,
                Title: 'Address'
            },
            locationName: {
                Type: FilterType.text,
                Title: 'Location'
            },
            zipCode: {
                Type: FilterType.Text,
                Title: 'Zip Code'
            },
            city: {
                Type: FilterType.Text,
                Title: 'City'
            },
            stateId: {
                Type: FilterType.Select,
                Valuelist: '/api/location/statevaluelist/234',
                Title: 'State'
            }
        },
        Pagination: DefaultPagination,
        Columns: {
            locationName: {
                Type: ColumnType.Text,
                LinkAppPage: true,
                Title: 'Location',
                Sorted: 'ascend',
                Width: 150
            },
            formattedAddress: {
                Type: ColumnType.Text,
                Title: 'Address',
                Width: 150
            },
            locationStateCode: {
                Type: ColumnType.Text,
                Title: 'State',
                Width: 50
            },
            zipCode: {
                Type: ColumnType.Text,
                Title: 'Zip Code',
                Width: 80
            },
            city: {
                Type: ColumnType.Text,
                Title: 'City',
                Width: 100
            },
            locationTypeStr: {
                Type: ColumnType.Text,
                Title: 'Loacation Type',
                Width: 100
            },
            landing: {
                RenderText: (text, record) => {
                    let landingText1 = "";
                    let landingText2 = "";
                    switch (text) {
                        case 1:
                            landingText1 = 'MON';
                            break;
                        case 2:
                            landingText1 = 'TUES';
                            break;
                        case 3:
                            landingText1 = 'WED';
                            break;
                        case 4:
                            landingText1 = 'THURS';
                            break;
                        case 5:
                            landingText1 = 'FRI';
                            break;
                        case 6:
                            landingText1 = 'SAT';
                            break;
                        case 7:
                            landingText1 = 'SUN';
                            break;
                        default:
                            landingText1 = '';
                            break;
                    }
                    switch (record.secondaryLanding) {
                        case 1:
                            landingText2 = 'MON';
                            break;
                        case 2:
                            landingText2 = 'TUES';
                            break;
                        case 3:
                            landingText2 = 'WED';
                            break;
                        case 4:
                            landingText2 = 'THURS';
                            break;
                        case 5:
                            landingText2 = 'FRI';
                            break;
                        case 6:
                            landingText2 = 'SAT';
                            break;
                        case 7:
                            landingText2 = 'SUN';
                            break;
                        default:
                            landingText2 = '';
                            break;
                    }
                    return landingText2 ? `${landingText1}/${landingText2}` : landingText1
                },
                Title: 'Landing Day',
                Width: 100
            },
            apptOne: {
                Type: ColumnType.Text,
                Title: 'APPT 1',
                Width: 50,
                NoSort: true
            },
            apptTwo: {
                Type: ColumnType.Text,
                Title: 'APPT 2',
                Width: 50,
                NoSort: true
            },
            dateChangeStr: {
                Type: ColumnType.Text,
                Title: 'Date Change',
                Width: 60,
                NoSort: true
            },
            phone: {
                Type: ColumnType.Phone,
                Title: 'Phone 1',
                Width: 100
            },
            phone2: {
                Type: ColumnType.Phone,
                Title: 'Phone 2',
                Width: 100
            },
            email: {
                Type: ColumnType.Text,
                Title: 'Email 1',
                Width: 180
            },
            email2: {
                Type: ColumnType.Text,
                Title: 'Email 2',
                Width: 180
            },
            lumperStr: {
                Type: ColumnType.Text,
                Title: 'Lumper',
                Width: 80,
                NoSort: true
            },
            overnightParkingStr: {
                Type: ColumnType.Text,
                Title: 'O/Park',
                Width: 60,
                NoSort: true
            },
            // lumperPrice: {
            //     Type: ColumnType.Number,
            //     Title: 'WH Price',
            //     Width: 70,
            //     NoSort: true
            // },
            // workingDaysStr: {
            //     Type: ColumnType.Text,
            //     Title: 'Hours',
            //     Width: 130,
            //     NoSort: true
            // },
        },
        Actions: {
            Width: 60,
            Items: [
                Action.Delete
            ]
        }
    },
    Form: {
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        locationType: {
                                            Title: 'Location Type',
                                            Field: ElementTypes.Generic(ElementTypes.Combo, {
                                                dataSource: [{ "value": '', "text": "" },
                                                { "value": 1, "text": "Shipper" },
                                                { "value": 2, "text": "Receiver" },
                                                { "value": 3, "text": "Cold Storage" }]
                                            })
                                        },
                                        dateChange: {
                                            Title: 'Date Change Needed',
                                            Field: ElementTypes.Generic(ElementTypes.Combo, {
                                                dataSource: [
                                                    {
                                                                    value: null,
                                                                    text: ''
                                                                },
                                                                {
                                                                    value: 1,
                                                                    text: 'Standard',
                                                                    options: {
                                                                        style: { color: 'blue' }
                                                                    }
                                                                },
                                                                {
                                                                    value: 2,
                                                                    text: 'Date Change',
                                                                    options: {
                                                                        style: {color: '#EF2112' }
                                                                    }
                                                                },
                                                ],
                                                style: (o, data) => data === 2 ? {'color': '#EF2112'} : {'color': 'blue'},
                                                fieldName: 'Date Change Needed',
                                            }),
                                        },
                                        // doorIds: {
                                        //     Title: 'Doors',
                                        //     Field: ElementTypes.Generic(ElementTypes.MultipleSelect, {
                                        //         dataSource: '/api/Door/multiplevaluelist',
                                        //         selectedValueType: 'multipleDoorName'
                                        //     })
                                        // }
                                    }
                                }
                            ],
                            locationName: {
                                Title: 'Warehouse Name',
                                Field: ElementTypes.TextField(),
                                Validations: [ElementValidations.Required()]
                            },
                            formattedAddress: {
                                Title: 'Address',
                                Field: ElementTypes.AddressField(),
                                Validations: [ElementValidations.Required()],
                                Dependencies: [
                                    ['map', 'value'],
                                    ['streetName', 'street'],
                                    ['streetNumber', 'streetNumber'],
                                    ['countryId', 'countryId'],
                                    ['stateCode', 'stateCode'],
                                    ['zipCode', 'zip'],
                                    ['city', 'city'],
                                    ['lat', 'lat'],
                                    ['lng', 'lng']
                                ]
                            },
                            additionalAddress: {
                                Title: 'Additional Address',
                                Field: ElementTypes.AddressField()
                            },
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        city: {
                                            Title: 'City',
                                            Field: ElementTypes.TextField()
                                        },
                                        stateCode: {
                                            Title: 'State',
                                            Dependencies: ['city'],
                                            Validations: [ElementValidations.Required()],
                                            Field: ElementTypes.Generic(ElementTypes.SelectWithCache, [
                                                [ElementTypes.Params.dataSource, '/api/location/statekeyvaluelist/'],
                                                [ElementTypes.Params.parentValueType, 'countryId'],
                                                [ElementTypes.Params.reloadOnParentChange, true],
                                                [ElementTypes.Params.selectedValueType, 'stateCode'],
                                                [ElementTypes.Params.editability, o => o && o['countryId'] > 0]
                                            ])
                                        },
                                        zipCode: {
                                            Title: 'Zip Code',
                                            Field: ElementTypes.TextField()
                                        },
                                        countryId: {
                                            Title: 'Country',
                                            Dependencies: ['stateId', 'city', 'stateCode'],
                                            Validations: [ElementValidations.Required()],
                                            //Field: ElementTypes.SelectWithCache('/api/location/countryvaluelist')
                                            Field: ElementTypes.Generic(ElementTypes.SelectWithCache, {
                                                dataSource: '/api/location/countryvaluelist',
                                                selectedValueType: 'countryName'
                                            })
                                        }
                                    }
                                }
                            ],
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        contactTypeId: {
                                            Title: 'Contact Type',
                                            Field: ElementTypes.Generic(ElementTypes.ComboAdd, {
                                                dataSource: '/api/contacttype/valuelistempty/',
                                                addparams: {
                                                    TitlePrefix: '',
                                                    PopupConfig: ContactTypeConfig
                                                },
                                                selectedValueType: 'contactType',
                                                style: { maxWidth: '300px' },
                                            })
                                        },
                                        contact: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Contact Name'
                                        },
                                        phone: {
                                            Field: ElementTypes.Phone(),
                                            Title: 'Phone'
                                        },
                                        phoneExt: {
                                            fieldClasses: ['mini'],
                                            Title: 'Ext.',
                                            Field: ElementTypes.IntField()
                                        },
                                        email: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Email',
                                            Validations: [ElementValidations.Email()]
                                        },
                                    }
                                }
                            ],
                            Group4: [
                                {
                                    Title: 'Another Contact 1',
                                    Fields: {
                                        contact2: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Contact Name'
                                        },
                                        phone2: {
                                            Field: ElementTypes.Phone(),
                                            Title: 'Phone'
                                        },
                                        phone2Ext: {
                                            fieldClasses: ['mini'],
                                            Title: 'Ext.',
                                            Field: ElementTypes.IntField()
                                        },
                                        email2: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Email',
                                            Validations: [ElementValidations.Email()]
                                        }
                                    }
                                }
                            ],
                            Group5: [
                                {
                                    Title: 'Another Contact 2',
                                    Fields: {
                                        contact3: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Contact Name'
                                        },
                                        phone3: {
                                            Field: ElementTypes.Phone(),
                                            Title: 'Phone'
                                        },
                                        phone3Ext: {
                                            fieldClasses: ['mini'],
                                            Title: 'Ext.',
                                            Field: ElementTypes.IntField()
                                        },
                                        email3: {
                                            Field: ElementTypes.TextField(),
                                            Title: 'Email',
                                            Validations: [ElementValidations.Email()]
                                        }
                                    }
                                }
                            ],
                            notes: {
                                Field: ElementTypes.Comment(),
                                Title: 'Notes'
                            },
                            Components: {
                                loadingRestriction: {
                                    Component: ComponentTypes.CheckBoxMultiple()
                                }
                            },
                        }
                    },
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        site: {
                                            Field: ElementTypes.Comment(),
                                            Title: 'Site'
                                        },
                                        locationLogin: {
                                            Field: ElementTypes.Comment(),
                                            Title: 'Login'
                                        },
                                        locationPassword: {
                                            Field: ElementTypes.Comment(),
                                            Title: 'Password'
                                        }
                                    }
                                }
                            ],
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        lumper: {
                                            Field: ElementTypes.Generic(ElementTypes.Combo, {
                                                dataSource: [   { "value": '', "text": "" },
                                                                { "value": 1, "text": "No" },
                                                                { "value": 2, "text": "Yes" }],
                                                search: false,
                                            }),
                                            Title: 'Lumper'
                                        },
                                        lumperPrice: {
                                            Field: ElementTypes.IntField(),
                                            Validations: [ElementValidations.IntPositiveField()],
                                            Title: 'Lumper Rate'
                                        },
                                        whEfs: {
                                            Field: ElementTypes.Generic(ElementTypes.Combo, {
                                                dataSource: [   { "value": 0, "text": " " },
                                                                { "value": 1, "text": "Yes" },
                                                                { "value": 2, "text": "No" },
                                                ],
                                                search: false,
                                            }),
                                            Title: 'EFS'
                                        },
                                    }
                                }
                            ],
                            Group3: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        overnightParking: {
                                            Field: ElementTypes.Generic(ElementTypes.Combo, {
                                                dataSource: [   { "value": '', "text": "" },
                                                                { "value": 1, "text": "No" },
                                                                { "value": 2, "text": "Yes" }],
                                                style: { maxWidth: '31.5%' },
                                                search: false,
                                            }),
                                            Title: 'Overnight Parking'
                                        },
                                        // loadingSequence: {
                                        //     Field: ElementTypes.TextField(),
                                        //     Title: 'Loading Sequence'
                                        // },
                                    }
                                }
                            ],
                            map: {
                                Title: 'Map',
                                Field: ElementTypes.MapField(),
                                Dependencies: [
                                    ['formattedAddress', 'value'],
                                    ['streetName', 'street'],
                                    ['streetNumber', 'streetNumber'],
                                    ['countryId', 'countryId'],
                                    ['stateCode', 'stateCode'],
                                    ['zipCode', 'zip'],
                                    ['city', 'city'],
                                    ['lat', 'lat'],
                                    ['lng', 'lng']
                                ]
                            },
                            Components: {
                                workingDays: {
                                    Component: ComponentTypes.ManagedSheet('name', {
                                        Title: 'Hours',
                                        List: {
                                            Columns: {
                                                name: {
                                                    Title: 'Day',
                                                    Field: ElementTypes.TableLabelField(),
                                                    Attributes: {
                                                        wrap: {
                                                            left: ''
                                                        }
                                                    }
                                                },
                                                working: {
                                                    Title: 'Work',
                                                    Field: ElementTypes.CheckBoxModern()
                                                },
                                                from: {
                                                    Title: 'From',
                                                    Field: ElementTypes.TextField((o) => o.working),
                                                    Validations: [ElementValidations.Required(), ElementValidations.Time()],
                                                    Width: 45
                                                },
                                                to: {
                                                    Title: 'To',
                                                    Field: ElementTypes.TextField((o) => o.working),
                                                    Validations: [ElementValidations.Required(), ElementValidations.Time()],
                                                    Width: 45
                                                },
                                                landing: {
                                                    Title: 'Landing Day',
                                                    Field: ElementTypes.CheckBoxModern(),
                                                    Width: 70
                                                },
                                                apptOne: {
                                                    Title: 'APPT 1',
                                                    Field: ElementTypes.TextField((o) => o.landing),
                                                    Validations: [ElementValidations.Time()],
                                                    Width: 35,
                                                },
                                                apptTwo: {
                                                    Title: 'APPT 2',
                                                    Field: ElementTypes.TextField((o) => o.landing && o.apptOne),
                                                    Validations: [ElementValidations.Time()],
                                                    Width: 35,
                                                },
                                                secondaryLanding: {
                                                    Title: 'Secondary Day',
                                                    Field: ElementTypes.CheckBoxModern(
                                                        'secondaryLanding'
                                                    ),
                                                    Width: 50,
                                                },
                                                comment: {
                                                    Title: 'Comment',
                                                    Field: ElementTypes.TextField(),
                                                    Width: 200
                                                }
                                            }
                                        }
                                    })
                                }
                            },
                            hours: {
                                Field: ElementTypes.Comment(),
                                Title: 'Hours Comment'
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars()
    }
};

export default Location;