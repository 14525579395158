import React, { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import Modal from 'react-modal';
import { Input } from 'antd';
import Loading from '../../screen/loading';
import { getRevisionOrdersAdmin, rejectRevision } from '../../../../store/actions/revisionOrderAdmin/revisionOrderAdmin';
const { TextArea } = Input;

const RejectModal = ({ 
    id, 
    rejectModalIsOpen,
    setRejectModalIsOpen,
    rejectRevision,
    rejectRevisionOrderLoading,
    hasError,
  }) => {
  const [rejectReasonText, setRejectReasonText] = useState('');
  
  const closeModal = useCallback(() => {
    setRejectModalIsOpen(false);
  }, [setRejectModalIsOpen]);

  const handleReject = () => {
    rejectRevision('CustomerOrderRevisionAdmin', 'declinerevision', { ids: [id], rejectReason: rejectReasonText });
  }
  
  useEffect(() => {
    if (hasError) {
      closeModal();
    }
}, [closeModal, hasError]);
  
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '400px',
      minHeight: '200px',      
      borderRadius: '8px',
      padding: 0,
    },
  };

  if (rejectRevisionOrderLoading) {
    return (
      <Modal
        isOpen={rejectModalIsOpen}
        style={customStyles}
        >
        <div style={{ marginTop: '12% '}}>
          <Loading />
        </div>
      </Modal>
    )
  };

  return (
    <Modal
        isOpen={rejectModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='reject_modal__container'>
          <button onClick={closeModal} className='revision__x-button'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
            </svg>
          </button>
          <div className='reject_modal_text'>Reject Revision ?</div>
          <TextArea
            placeholder="Reject reason"
            autoSize={{ minRows: 2, maxRows: 6 }}
            className='reject-reason__input'
            status={"error"}
            onChange={e => setRejectReasonText(e.target.value)}
          />
          <div className='buttons-container__reject-modal'>
            <button className='cancel-modal-button' type="submit" onClick={closeModal}>CANCEL</button>
            <button className={`accept-modal-button ${!rejectReasonText && 'reject-btn_disabled'}`} type="submit" disabled={!rejectReasonText} onClick={handleReject}>CONFIRM</button>
          </div>
        </div>  
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  total: state.revisionOrderAdmin.total,
  orderRejected: state.revisionOrderAdmin.orderRejected,
  rejectRevisionOrderLoading: state.revisionOrderAdmin.rejectRevisionOrderLoading,
  hasError: state.revisionOrderAdmin.hasError,
});

const mapDispatchToProps = dispatch => ({
  rejectRevision: (resource, endPoint, data) => dispatch(rejectRevision(resource, endPoint, data)),
  getRevisionOrdersAdmin: (resource) => dispatch(getRevisionOrdersAdmin(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);