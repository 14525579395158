import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Radio, Switch, message } from 'antd';
import { getCustomerPrice, addEmptyLineOnPriceList, addCustomerPrice, removeEmptyLinesOnPriceList, resetError, setPerPalletType } from '../../../../store/actions/customers/customers';
import { getAllLocationDoorList, getFavoriteLocationDoorList } from '../../../../store/actions/customerOrderLocations/customerOrderLocations';
import { getContentWidth } from '../../../../store/reducers/accountReceivableReducer/helper/general';
import { CustomerPriceSelectors } from '../../../../store/selectors/customerPriceSelectors';
import Row from './row';
import HeaderRow from './headerRow';
import Loading from "../../screen/loading";
import ChangePriceTemplateModal from './ChangePriceTemplateModal';
import DuplicateLocationsModal from './DuplicateLocationsModal';

const CustomerPriceSpreadsheet = ( { customerId } ) => {
    const dispatch = useDispatch();
    const { state, customersPriceList, hasError, loading, actionMessage, customersList, favoriteDoorsLocationsLoading, allDoorsLocationsLoading } = CustomerPriceSelectors();

    const customerPriceTemplateType = customersList?.data?.filter(customer => Number(customer.id) === Number(customerId))[0]?.priceTemplateType;
    const priceType = customerPriceTemplateType ? customerPriceTemplateType : customersList?.priceTemplateType;
    const [priceTemplateType, setPriceTemplateType] = useState(null);
    const [prevPrice, setPrevPrice] = useState(null);
    const [duplicateLocationsModalIsOpen, setDuplicateLocationsModalIsOpen] = useState(false);
    const [changePriceTemplateModalIsOpen, setChangePriceTemplateModalIsOpen] = useState(false);
    const assignedRef = useRef({});
    const history = useHistory();
    const resource = 'customers';
    const scrollSize = 16;
    const defRowHeight = 20;

    let customerPriceTemplateTypeText = '';
    if (priceType === 1 || !priceType) {
        customerPriceTemplateTypeText = 'SPOT'
    } else if (priceType === 2) {
        customerPriceTemplateTypeText = 'PricePerPall'
    } else if (priceType === 3) {
        customerPriceTemplateTypeText = 'PricePerRange'
    } else if (priceType === 4) {
        customerPriceTemplateTypeText = 'PricePerPound'
    }

    let noTemplateSelected = null;
    if ((priceType === 1 || !priceType) && !loading) {
        noTemplateSelected = <div className='no-template-selected-text'>No template selected</div>;
    };

    useEffect(() => {
        priceTemplateType && history.replace(`/loadplanning/customerPrice/${priceTemplateType?.toLowerCase()}?id=${customerId}`);
    }, [customerId, history, priceTemplateType]);

    useEffect(() => {
        setPriceTemplateType(customerPriceTemplateTypeText)
    }, [customerPriceTemplateType, customerPriceTemplateTypeText, customersList]);

    useEffect(() => {
        if (customerPriceTemplateTypeText && customerPriceTemplateTypeText !== 'SPOT') {
            dispatch(getCustomerPrice(customerPriceTemplateTypeText, customerId));
        }
    }, [resource, customersList, hasError, customerPriceTemplateTypeText, dispatch, customerId]);

    useEffect(() => {
        if (hasError) {
            message.error(actionMessage);
            dispatch(resetError(resource));
        }
    }, [hasError, actionMessage, resource, dispatch]);

    useEffect(() => {
        dispatch(getAllLocationDoorList('location', 'locationdoorlist'));
        dispatch(getFavoriteLocationDoorList('location', 'locationdoorlistbyparentid', customerId));
    }, [customerId, dispatch]);

    const addEmptyRow = () => {
        dispatch(addEmptyLineOnPriceList(resource, customerId));
    };

    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const width = getContentWidth(state[resource].headers) + scrollSize;
    
    const header = useMemo(() =>
        <div style={{ overflow: 'hidden' }}>
            <div
                ref={listHeaderReference}
                style={{
                    overflow: 'hidden',
                    borderRadius: '0 12px 0 0'
                }}>
                <HeaderRow resource={resource} />
            </div>
        </div>,
    [resource]);
    
    const getAsignedItemSize = useCallback(
        index => customersPriceList[index] ? customersPriceList[index].rowState?.height : defRowHeight,
        [customersPriceList]);

    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                priceTemplateType={priceTemplateType}
                customerId={customerId}
                mainScroll={mainScroll}
            />
            : null
    ), [customerId, getAsignedItemSize, priceTemplateType]);

    const allOrdersList = useMemo(() => customersPriceList &&
        <AutoSizer disableWidth>
            {({ height }) => (
                <>
                    <VariableSizeList
                        ref={assignedRef}
                        outerRef={el => {
                            listReference.current = el;
                        }}
                        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                        itemData={customersPriceList}
                        itemCount={customersPriceList.length}
                        height={height}
                        itemSize={getAsignedItemSize}>
                        {RenderAssignedRow}
                    </VariableSizeList>
                </>
            )}
        </AutoSizer>
        , [customersPriceList, getAsignedItemSize, RenderAssignedRow]);

    const addBtnDisabled = !customersPriceList?.filter(item => !item?.rowState?.id).length;
    const cancelBtnDisabled = !customersPriceList?.filter(item => !item?.rowState?.id).length;
    const bothSameLocation = customersPriceList && (customersPriceList[0]?.rowState.data.pickLocationId === customersPriceList[0]?.rowState.data.dropLocationId) &&
                            ((customersPriceList?.length && customersPriceList[0]?.rowState.data.pickLocationId !== '') ||
                            (customersPriceList?.length && customersPriceList[0]?.rowState.data.dropLocationId !== ''));

    const firstPickId = customersPriceList && customersPriceList[0]?.rowState.data.pickLocationId
    const pickLocationsIndexes = customersPriceList?.map((item, index) => item?.rowState.data.pickLocationId === firstPickId && index).filter(item => item !== false);
    const correspondingDropLocations = customersPriceList?.filter((item, index) => pickLocationsIndexes.includes(index));
    const correspondingDropLocationsIds = correspondingDropLocations?.map(item => item?.rowState.data.dropLocationId);
    const isDuplicate = new Set(correspondingDropLocationsIds).size !== correspondingDropLocationsIds?.length;

    useEffect(() => {
        setDuplicateLocationsModalIsOpen(bothSameLocation);
    }, [bothSameLocation]);

    const btnDisabled = !customersPriceList?.filter(item => !item?.rowState?.id).length ||
                        !customersPriceList[0]?.rowState.data.pickLocationId ||
                        !customersPriceList[0]?.rowState.data.dropLocationId ||
                        isDuplicate || bothSameLocation;

    const handleCancel = () => {
        dispatch(removeEmptyLinesOnPriceList(resource));
    }

    const handleSave = () => {
        dispatch(addCustomerPrice(resource, customersPriceList, priceTemplateType, customerId));
    }

    const handleSaveAndBack = () => {
        dispatch(addCustomerPrice(resource, customersPriceList, priceTemplateType, customerId));
        history.replace(`/loadplanning/customerdetails/${customerId}`);
    }

    const buttonsContainer = (
        <div className='price-buttons__container'>
            <button 
                className={`price-button ${btnDisabled && 'price-button-disabled'} `} 
                disabled={btnDisabled}
                onClick={handleSave}
            >
                Save Price
            </button>
            <button 
                className={`price-button ${btnDisabled && 'price-button-disabled'} `} 
                disabled={btnDisabled}
                onClick={handleSaveAndBack}
            >
                Save & Back
            </button>
            <separator className='customerOrder-admin__separator' vertical=''></separator>
            <button 
                className={`price-button price-button__cancel ${cancelBtnDisabled && 'price-button-disabled'} `}
                disabled={cancelBtnDisabled}
                onClick={handleCancel}
            >
                Cancel
            </button>
        </div>
    );

    const content = (<>
        <div style={{ display: 'flex', paddingLeft: '10px' }}>{header}</div>
        <div style={{ flexGrow: 1, width: '100%', marginLeft: '10px' }}>{allOrdersList}</div>
        {buttonsContainer}
        <div style={{
            width: 'calc(100% - 16px)',
            overflowX: 'auto',
            flexShrink: 0,
            marginBottom: '3px',
            position: 'absolute',
            bottom: '1px',
            height: '8px',
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                    borderBottom: `${width < window.innerWidth ? 'none' : ''}`,
                }} />
        </div>
    </>);

    const onChange = (e) => {
        const pathArray = window.location.pathname.split('/');
        const secondLevelLocation = pathArray[3];
        setPrevPrice(secondLevelLocation);
        setChangePriceTemplateModalIsOpen(true);
        setPriceTemplateType(e.target.value);
    };

    const radioGroup = 
    (<Radio.Group onChange={onChange} value={priceTemplateType} className='customer-price__radioGroup'>
        <Radio value={'SPOT'}>Spot</Radio>
        <Radio value={'PricePerPall'}>Price by Pallet</Radio>
        <Radio value={'PricePerRange'}>Price by Range</Radio>
        <Radio value={'PricePerPound'}>Price by Pound</Radio>
    </Radio.Group>);

    const addPriceButton = (
        <div>
            <button 
                onClick={addEmptyRow} 
                className={`customer-price__add-price-btn ${!addBtnDisabled && 'price-button-disabled'} `}
                disabled={!addBtnDisabled}
                >Add Price
            </button>
        </div>
    );

    const handleSwitchChange = e => {
        if (e) {
            dispatch(setPerPalletType(resource, true));
        } else {
            dispatch(setPerPalletType(resource, false));
        }
    };

    const switchTypeContainer = (<div className='priceTypeSwitch' >
            <span className='gross-text'>GROSS</span>
            <Switch defaultChecked={true} className='priceTypeSwitch' onChange={handleSwitchChange} />
            <span className='perpallet-text'>PER PALLET</span>
        </div>
    );

    return <>
        {(loading || allDoorsLocationsLoading || favoriteDoorsLocationsLoading) && !noTemplateSelected ? <Loading /> : 
            <>
                {radioGroup}
                <div style={{display: 'flex'}}>
                    {priceTemplateType !== 'SPOT' ? addPriceButton : ''}
                    {priceType === 2 ? switchTypeContainer : ''}
                </div>
                {changePriceTemplateModalIsOpen ? 
                    <ChangePriceTemplateModal
                        changePriceTemplateModalIsOpen={changePriceTemplateModalIsOpen}
                        setChangePriceTemplateModalIsOpen={setChangePriceTemplateModalIsOpen}
                        customerId={customerId}
                        priceTemplateType={priceTemplateType}
                        setPriceTemplateType={setPriceTemplateType}
                        prevPrice={prevPrice}/> : ''
                }
                {duplicateLocationsModalIsOpen ?
                    <DuplicateLocationsModal
                        duplicateLocationsModalIsOpen={duplicateLocationsModalIsOpen}
                        setDuplicateLocationsModalIsOpen={setDuplicateLocationsModalIsOpen} /> : ''
                }
                {noTemplateSelected ? noTemplateSelected : content}
            </>}
        </>;
};

export default CustomerPriceSpreadsheet;