import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { message } from 'antd';
import Modal from 'react-modal';
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { getRevisionOrdersAdmin, resetError } from '../../../../store/actions/revisionOrderAdmin/revisionOrderAdmin';
import { getContentWidth } from '../../../../store/reducers/revisionOrderAdmin/helper/general';
import { useClientHubNewRequest } from "../../../hook/hub/useClientHubNewRequest";
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";
import { getCustomerOrderAdmin } from '../../../../store/actions/customerOrderAdmin/customerOrderAdmin';

const RevisionOrderAdmin = props => {
    const { resource,
            getRevisionOrdersAdmin,
            loading,
            hasError,
            state,
            orderRejected,
            orderAccepted,
            messageText,
            resetError,
            acceptRevisionOrderLoading,
            total,
            getCustomerOrderAdmin,
            userId,
        } = props;
    const defRowHeight = 40;
    const scrollSize = 0;
    const customerOrderAdminRef = useRef(null);
    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const customerOrderAdminHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (customerOrderAdminHeaderReference.current && listReference.current && e.target) {
            customerOrderAdminHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    const [newRequestCount, setNewRequestCount] = useState(props.total);
    useClientHubNewRequest((message) => setNewRequestCount(message.newRequestCount), 'notification');
    useClientHubOrder((message) => {
        if (userId !== message.byId) {
            getRevisionOrdersAdmin('CustomerOrderRevisionAdmin', 'withoutLoading')
        }
    }, 'notification');

    useEffect(() => {
        getRevisionOrdersAdmin('CustomerOrderRevisionAdmin');
        getCustomerOrderAdmin('customerOrderAdmin');
    }, [resource, getRevisionOrdersAdmin, getCustomerOrderAdmin, newRequestCount]);

    useEffect(() => {
        if (hasError) {
            message.error(messageText, 3);
            setTimeout(() => resetError(resource), 3000);
        }
    }, [hasError, resource, messageText, resetError]);

    useEffect(() => {
        if (orderAccepted) {
            message.success('Order Revision Accepted', 3);
            resetError(resource);
        }
    }, [orderAccepted, resetError, resource]);

    useEffect(() => {
        if (orderRejected) {
            message.success('Order Revision Rejected', 3);
            resetError(resource);
        }
    }, [orderRejected, resetError, resource]);

    useEffect(() => {
        if (orderAccepted || orderRejected) {
            getRevisionOrdersAdmin('CustomerOrderRevisionAdmin');
        }
    }, [getRevisionOrdersAdmin, orderAccepted, orderRejected, total]);

    let revisionOrderAdminList = state[resource]?.revisionOrderAdminList;;

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        overflow: 'hidden',
        margin: '0 12px',
        borderRadius: '12px 12px 0 0',
        borderLeft: '1px solid #29CABA',
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const getRevisionOrderAdminListItemSize = useCallback(
        index => revisionOrderAdminList?.[index] ? revisionOrderAdminList[index].rowState.height : defRowHeight,
        [revisionOrderAdminList]);

    const RenderRevisionOrderAdminListRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={customerOrderAdminRef}
                data={data[index]}
                itemSize={getRevisionOrderAdminListItemSize(index)}
                style={style}
                resource={resource}
                startOnScroll={startOnScroll}
                index={index}
            />
            : null
    ), [getRevisionOrderAdminListItemSize, resource]);

    const allRevisionOrderAdminList = useMemo(() => revisionOrderAdminList &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={customerOrderAdminRef}
                    outerRef={el => { listReference.current = el }}
                    style={{ 
                        overflowX: 'hidden', 
                        overflowY: 'auto',                        
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0px 0px 12px 12px',
                    }}
                    itemData={revisionOrderAdminList}
                    itemCount={revisionOrderAdminList.length}
                    height={revisionOrderAdminList && (revisionOrderAdminList.length * 29) > height ? height : revisionOrderAdminList.length * 28.2}
                    itemSize={getRevisionOrderAdminListItemSize}>
                    {RenderRevisionOrderAdminListRow}
                </VariableSizeList>
            </>)}
    </AutoSizer>
    , [revisionOrderAdminList, getRevisionOrderAdminListItemSize, RenderRevisionOrderAdminListRow]);

    const content = (<>
        <div style={{ height: '8px'}}></div>
        <div className='headerContainer'>{header}</div>
        <div style={{ flexGrow: 1, margin: '0 12px' }}>{allRevisionOrderAdminList}</div>
        <div style={{
            width: 'calc(100% - 16px)',
            overflowX: 'auto',
            flexShrink: 0,
            marginBottom: '3px',
            position: 'absolute',
            bottom: '1px',
            height: '8px',
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                    borderBottom: `${width < window.innerWidth ? 'none' : ''}`,
                }} />
        </div>
    </>);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '400px',
        minHeight: '200px',
        borderRadius: '8px',
        padding: 0,
        border: 0,
    },
};

    if (acceptRevisionOrderLoading) {
        return (
        <Modal
            isOpen={true}
            style={customStyles}
            >
            <div style={{ marginTop: '12% '}}>
            <Loading />
            </div>
        </Modal>
        )
    };

    return <>{loading? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    loading: state.revisionOrderAdmin.loading,
    total: state.revisionOrderAdmin.total,
    orderRejected: state.revisionOrderAdmin.orderRejected,
    messageText: state.revisionOrderAdmin.messageText,
    hasError: state.revisionOrderAdmin.hasError,
    orderAccepted: state.revisionOrderAdmin.orderAccepted,
    acceptRevisionOrderLoading: state.revisionOrderAdmin.acceptRevisionOrderLoading,
    userId: state.auth.userId,
});

const mapDispatchToProps = (dispatch) => ({
    getRevisionOrdersAdmin: (resource, loadingType) => dispatch(getRevisionOrdersAdmin(resource, loadingType)),
    getCustomerOrderAdmin: (resource, endPoint) => dispatch(getCustomerOrderAdmin(resource, endPoint)),
    resetError: resource => dispatch(resetError(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevisionOrderAdmin);